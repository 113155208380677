import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap'
import styled from '@emotion/styled'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { myContext } from '../../provider'

const Content = styled.div`
  white-space: pre-wrap;
`

const BoldComponent = styled.span`
  font-size: bold;
`

const Paragraph = styled.p`
  padding: 1rem;
`

const ImageContainer = styled.div`
  text-align: center;
`

const Header = styled.div`
  padding: 2rem 1rem;
`

const Bold = ({ children }) => <BoldComponent>{children}</BoldComponent>
const Text = ({ children }) => <Paragraph>{children}</Paragraph>
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    },
  },
}

const BlogPostTemplate = ({ data }) => {
  return (
    <myContext.Consumer>
      {({ language }) => {
        const post = data.contentfulBlogPost
        const siteTitle = data.site.siteMetadata.title
        return (
          <Layout>
            <Helmet
              title={`${post[`title_${language}`]} - ${siteTitle}`}
            ></Helmet>
            <Container>
              <Content>
                <ImageContainer>
                  <img
                    alt={`${post[`title_${language}`]}`}
                    src={post.image ? post.image.sizes.src : ''}
                  />
                </ImageContainer>
                <Header>
                  <h1>{`${post[`title_${language}`]}`}</h1>
                  <p
                    style={{
                      display: 'block',
                    }}
                  >
                    {new Date(post.createdAt).toDateString()}
                  </p>
                </Header>
                <div>
                  <div>{renderRichText(post[`post_${language}`], options)}</div>
                </div>
              </Content>
            </Container>
          </Layout>
        )
      }}
    </myContext.Consumer>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title_english
      title_vietnamese
      createdAt
      image {
        sizes {
          src
        }
        createdAt
      }
      post_english {
        raw
      }
      post_vietnamese {
        raw
      }
    }
  }
`
